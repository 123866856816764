import classNames from 'classnames';
import './style.sass';

function AtomIconButton({ color, type = 'button', children, onClick }) {
  const colorClassMapping = {
    primary: 'primary',
    secondary: 'secondary',
    selected: 'selected',
    orange: 'orange',
    blue: 'blue',
    green: 'green',
  };

  return (
    <button type={type} className={classNames('atom-icon-button', colorClassMapping[color])} onClick={onClick}>
      {children}
    </button>
  );
}

export default AtomIconButton;
