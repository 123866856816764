import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { getCurrentUserProfile } from 'src/store/actions/auth';
import { routes } from 'src/utils/routes';
import { createErrorNotification } from 'src/utils/notifications';
import { useLanguage } from 'src/hooks';
import Loader from 'src/components/loader';
import { setLang } from 'src/store/actions/app';
import { LOCALIZATION } from 'src/utils/constant';

function LoginExternalPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const { localizeMessage } = useLanguage();

  useEffect(() => {
    if (query) {
      if (query.lang) {
        setLang(query.lang === 'en' ? LOCALIZATION.en_US : LOCALIZATION.ru_RU);
      }
      localStorage.setItem('accessToken', query.accessToken);
      localStorage.setItem('refreshToken', query.refreshToken);
      getCurrentUserProfile().then((user) => {
        if (user) {
          if (query.projectUuid) {
            navigate(routes.projectDetail.path.replace(':projectUuid', query.projectUuid));
            return;
          }
          navigate(routes.homepage.path);
        } else {
          createErrorNotification(localizeMessage.YOUR_YANDEX_ACCOUNT_DOES_NOT_HAVE_EMAIL);
          setTimeout(() => {
            navigate(routes.login.path);
          }, 200);
        }
      });
    }
  }, [query]);

  return (
    <Stack alignItems="center" style={{ height: '100vh' }}>
      <Loader />
    </Stack>
  );
}

export default LoginExternalPage;
