import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AtomButton from 'src/components/atoms/button';
import AtomIconButton from 'src/components/atoms/button/IconButton';
import { Badge } from 'src/components/badge';
import { useCurrentPath } from 'src/hooks';
import { BasketAltIcon, ChatAltIcon } from 'src/icons';
import { routes } from 'src/utils/routes';
import NavigationDrawer from './components/NavigationDrawer';
import ProfileMenu from './components/ProfileMenu';
import classes from './header.module.sass';
import eventBus from 'src/utils/eventBus';
import { EVENTS, LOCALIZATION } from 'src/utils/constant';
import SelectProjectModal from 'src/components/molecules/projects/SelectProjectModal';
import { CalendarMonth } from '@mui/icons-material';

function Header() {
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const { projectUuid } = useParams();

  const order = useSelector((state) => state.app.order);
  const projectSettings = useSelector((state) => state.app.projectSettings);
  const currentProject = useSelector((state) => state.app.currentProject);
  const headerAccesses = currentProject?.accesses?.header ?? {};

  const typeProcessBDK = projectSettings?.typeProcessBDK ?? 'bdk';

  const numOfPackages = useMemo(() => {
    const countPackages = (packages) => packages?.reduce((p, c) => (p += c.count), 0) ?? 0;
    if (projectUuid) {
      return countPackages(order?.find((item) => item.projectUuid === projectUuid)?.packages);
    }
    return order?.reduce((p, c) => (p += countPackages(c.packages)), 0);
  }, [projectUuid, order]);

  const numOfUnreadChat = useMemo(() => {
    return 0;
  }, []);

  const handleClickLogo = () => {
    navigate(routes.homepage.path);
  };

  const navigationItems = [
    // {
    //   label: 'Мои мероприятия',
    //   path: routes.homepage.path,
    // },

    {
      label: 'Мероприятия',
      path: routes.projects.path,
      isHidden: currentProject && !headerAccesses.events,
    },
  ];
  const renderCalendarButton = () => {
    return (
      <AtomIconButton
        color="green"
        onClick={() => {
          if (projectUuid) {
            navigate(routes.calendar.path.replace(':projectUuid', projectUuid));
          } else {
            eventBus.emit(EVENTS.OPEN_SELECT_PROJECT_MODAL, {
              onSelect: (projectUuid) => {
                navigate(routes.calendar.path.replace(':projectUuid', projectUuid));
              },
            });
          }
        }}
      >
        <CalendarMonth />
      </AtomIconButton>
    );
  };

  const renderBasketButton = () => {
    return (
      <AtomIconButton
        color="blue"
        onClick={() => {
          if (projectUuid) {
            navigate(routes.orderDetail.path.replace(':projectUuid', projectUuid));
          } else {
            eventBus.emit(EVENTS.OPEN_SELECT_PROJECT_MODAL, {
              onSelect: (projectUuid) => {
                navigate(routes.orderDetail.path.replace(':projectUuid', projectUuid));
              },
            });
          }
        }}
      >
        <BasketAltIcon />
      </AtomIconButton>
    );
  };

  const renderChatButton = () => {
    return (
      <AtomIconButton
        color="orange"
        onClick={() => {
          if (projectUuid) {
            navigate(routes.projectChat.path.replace(':projectUuid', projectUuid));
          } else {
            eventBus.emit(EVENTS.OPEN_SELECT_PROJECT_MODAL, {
              onSelect: (projectUuid) => {
                navigate(routes.projectChat.path.replace(':projectUuid', projectUuid));
              },
            });
          }
        }}
      >
        <ChatAltIcon />
      </AtomIconButton>
    );
  };

  return (
    <Stack direction="row" overflow="hidden" className={classes.header} justifyContent={'center'}>
      <Stack
        className={window.location.href.endsWith('/projects') ? classes.headerWrapper : classes.headerWrapperBig}
        flexDirection="row"
      >
        <Box className={classes.logo}>
          <img src="/images/logo-dark.png" alt="logo" width="auto" onClick={handleClickLogo} />
        </Box>

        <Box className={classes.navigation} flexGrow={1}>
          <Stack
            className={classes.navigationDesktop}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="8px"
            height="100%"
          >
            <Stack direction="row" gap="16px">
              {navigationItems.map((item) => {
                if (item.isHidden) return;
                const isActive = currentPath?.path === item.path;

                return (
                  <AtomButton
                    color={isActive ? 'selected' : undefined}
                    key={item.path}
                    onClick={() => navigate(item.path)}
                  >
                    {item.label}
                  </AtomButton>
                );
              })}
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="24px" flexWrap="wrap-reverse">
              {headerAccesses.calendar && renderCalendarButton()}
              {/* {numOfUnreadChat > 0 && typeProcessBDK === 'networking' && ( */}
              {/* <Badge id="chat" badgeContent={numOfUnreadChat}> */}
              {headerAccesses.chat && renderChatButton()}
              {/* </Badge> */}
              {headerAccesses.basket &&
                (numOfPackages > 0 ? (
                  <Badge id="shopping-cart" badgeContent={numOfPackages}>
                    {renderBasketButton()}
                  </Badge>
                ) : (
                  renderBasketButton()
                ))}
              {(!currentProject || headerAccesses.user) && <ProfileMenu />}
            </Stack>
          </Stack>

          <NavigationDrawer />

          <SelectProjectModal />
        </Box>
      </Stack>
    </Stack>
  );
}

export default Header;
