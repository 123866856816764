import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { useLanguage } from 'src/hooks';
import { projectsAPIs } from 'src/services';

const SpoilerInfo = ({ projectUuid, typeUuid, defaultText = '' }) => {
  const [isInfoClosed, setIsInfoClosed] = useState(localStorage.getItem('isBookingInfoClosed') > 0);
  const { lang, getLocalizationValue } = useLanguage();

  const handleCloseInfo = (val) => {
    setIsInfoClosed(!val);
    localStorage.setItem('isBookingInfoClosed', val ? 0 : 1);
  };

  const fetchInfoText = async () => {
    let result;
    try {
      const res = await projectsAPIs.getBookingTypes(projectUuid, {
        uuid: typeUuid,
      });
      const desc = res.message[0].localization[lang].description;
      if (desc) {
        result = desc;
      }
    } catch {}
    return result || defaultText;
  };
  const { data, isLoading, isFetching, refetch } = useQuery(['fetchInfoText', typeUuid], fetchInfoText, {
    enabled: !!(projectUuid && typeUuid),
  });
  return (
    <Accordion
      sx={{ width: '100%' }}
      defaultExpanded={!isInfoClosed}
      onChange={(_, val) => {
        handleCloseInfo(val);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {data ? (
          <Box
            sx={isInfoClosed && { maxHeight: '50px', overflow: 'hidden' }}
            dangerouslySetInnerHTML={{ __html: data }}
          />
        ) : (
          <Loader size="20px" />
        )}
      </AccordionSummary>
      {/* <AccordionDetails>
        <Box
          sx={{
            position: 'relative',
            py: '30px',
            px: '15px',
            border: '1px solid',
            borderColor: 'warning.main',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: data }} />
        </Box>
      </AccordionDetails> */}
    </Accordion>
  );
};

export default SpoilerInfo;
