import { DOCUMENT_TYPES, ENDPOINT } from '../utils/constant';
import { request } from '../utils/request';
import queryString from 'query-string';

export const userAPIs = {
  getCurrentUser: () =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}`, {
      method: 'GET',
    }),
  getAvatarCurrentUser: () =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.AVATAR}`, {
      method: 'GET',
    }),
  uploadAvatar: (payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.AVATAR}`, {
      method: 'PUT',
      body: payload,
    }),
  updateCurrentUser: (payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}`, {
      method: 'PATCH',
      body: payload,
    }),
  getCurrentUserPassportFile: () =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.PASSPORT_FILE}`, {
      method: 'GET',
    }),
  uploadPassportFile: (payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.PASSPORT_FILE}`, {
      method: 'PUT',
      body: payload,
    }),
  getCurrentUserPassportData: () =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.PASSPORT_DATA}`, {
      method: 'GET',
    }),
  updatePassportData: (payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.PASSPORT_DATA}`, {
      method: 'PUT',
      body: payload,
    }),
  deletePassportFile: () =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.PASSPORT}`, {
      method: 'DELETE',
    }),
  getUserProjects: (query) =>
    request(
      `${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.PROJECTS}${
        query ? '?' + query + '&public=true' : '?public=true'
      }`,
      {
        method: 'GET',
      }
    ),
  changePassword: (payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.PASSWORD}`, {
      method: 'PATCH',
      body: payload,
    }),
  getUserCompany: () =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.COMPANY}`, {
      method: 'GET',
    }),
  addCompany: (payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.COMPANY}`, {
      method: 'POST',
      body: payload,
    }),
  getUserOrders: (projectUuid) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.ORDERS}?projectUuid=${projectUuid}`, {
      method: 'GET',
    }),
  createUserOrder: (payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.ORDERS}`, {
      method: 'POST',
      body: payload,
    }),
  deleteUserOrder: (orderUuid) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.ORDERS}/${orderUuid}`, {
      method: 'DELETE',
    }),
  getDocuments: (queryParams) =>
    request(`/users/current/documents${queryParams ? '?' + queryString.stringify(queryParams) : ''}`, {
      method: 'GET',
    }),
  getOfferFile: (queryParams) =>
    request(
      `/documents/offer${queryParams ? '?' + queryString.stringify(queryParams) : ''}`,
      {
        method: 'GET',
      },
      true
    ),
  getDocumentFile: (docUuid) => request(`/users/current/documents/${docUuid}`, { method: 'GET' }, true),
  getMembersOfProject: (query) => request(`/users/current/members${query ? `?${query}` : ''}`, { method: 'GET' }),
  getDocumentMember: (memberUuid, type = DOCUMENT_TYPES.PASSPORT, projectUuid) =>
    request(
      `${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.MEMBERS}/${memberUuid}?type=${type}&projectUuid=${projectUuid}`,
      {
        method: 'GET',
      }
    ),
  checkAndCreateNewUser: (payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.MEMBERS}`, {
      method: 'POST',
      body: payload,
    }),
  updateMember: (memberUuid, payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.MEMBERS}/${memberUuid}`, {
      method: 'PATCH',
      body: payload,
    }),
  uploadDocumentOfMember: (memberUuid, payload) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.MEMBERS}/${memberUuid}`, {
      method: 'PUT',
      body: payload,
    }),
  getArchivedOrders: () =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.ARCHIVED_ORDERS}`, {
      method: 'GET',
    }),
  deletePassportOfMember: (memberUuid) =>
    request(`${ENDPOINT.USER.BASE}${ENDPOINT.USER.CURRENT}${ENDPOINT.USER.MEMBERS}/${memberUuid}`, {
      method: 'DELETE',
    }),
  getUserVizitsProject: (id) =>
    request(
      `${ENDPOINT.USER.BASE}${ENDPOINT.PROJECTS.CURRENT}${ENDPOINT.PROJECTS.BASE}/${id}${ENDPOINT.PROJECTS.VIZITS}`,
      {
        method: 'GET',
      }
    ),
  createVizit: (id, body) =>
    request(`/users/current/projects/${id}/vizits`, {
      method: 'POST',
      body,
    }),
  patchUserVizit: (id, body) =>
    request(`/users/current/projects/${id}/vizits`, {
      method: 'PATCH',
      body,
    }),
  updateLogoVizit: (id, body) =>
    request(`/users/current/projects/${id}/vizits`, {
      method: 'PUT',
      body,
    }),
  getMembersVizitsProject: (id) =>
    request(`/users/current/projects/${id}/vizits/members`, {
      method: 'GET',
    }),
  createMembersVizitsProject: (id, body) =>
    request(`/users/current/projects/${id}/vizits/members`, {
      method: 'POST',
      body,
    }),
  patchMembersVizitsProject: (projectUuid, id, body) =>
    request(`/users/current/projects/${projectUuid}/vizits/members/${id}`, {
      method: 'PATCH',
      body,
    }),
  uploadPhotoMembersVizitsProject: (projectUuid, id, body) =>
    request(`/users/current/projects/${projectUuid}/vizits/members/${id}`, {
      method: 'PUT',
      body,
    }),
  searchVizits: (projectUuid, query) =>
    request(`/users/current/projects/${projectUuid}/vizits/search${query ? '?' + query : ''}`, {
      method: 'GET',
    }),
  confirmMeeting: (projectUuid, body) =>
    request(`/users/current/projects/${projectUuid}/vizits/confirm`, {
      method: 'POST',
      body,
    }),
  getUserMeetings: (projectUuid, status = '', chatUuid) =>
    request(
      `/users/current/projects/${projectUuid}/vizits/meetings?status=${status}${
        chatUuid ? `&chatUuid=${chatUuid}` : ''
      }`,
      {
        method: 'GET',
      }
    ),
  getAvatarMemberVizits: (userUuid) =>
    userUuid
      ? request(`/users/current/avatars/${userUuid}`, {
          method: 'GET',
        })
      : null,
  updateMeetingStatus: (projectUuid, meetingUuid, body) =>
    request(`/users/current/projects/${projectUuid}/vizits/meetings/${meetingUuid}`, {
      method: 'PATCH',
      body,
    }),
  deleteUserVizit: (projectUuid) =>
    request(`/users/current/projects/${projectUuid}/vizits`, {
      method: 'DELETE',
    }),
  deleteUserCompany: () =>
    request(`/users/current/company`, {
      method: 'DELETE',
    }),
  validateMeeting: (projectUuid, body) =>
    request(`/users/current/projects/${projectUuid}/vizits/validate`, {
      method: 'POST',
      body,
    }),
  getSuggestionCompany: (query) =>
    request(`/users/current/company/suggestions${query ? `?query=${query}` : ''}`, {
      method: 'GET',
    }),
  getSuggestionAddress: (query) =>
    request(`/users/current/address/suggestions${query ? `?query=${query}` : ''}`, {
      method: 'GET',
    }),
  getSuggestionFullName: (query) =>
    request(`/users/current/fullname/suggestions${query ? `?query=${query}` : ''}`, {
      method: 'GET',
    }),
  sendVerifyCode: () => request(`/users/current/verified-code`, { method: 'POST' }),
  verifyCode: (code) => request(`/users/verify?code=${code}`, { method: 'GET' }),
  removeFromProject: (memberUuid, projectUuid) =>
    request(`/users/current/members/${memberUuid}/remove/${projectUuid}`, { method: 'POST' }),
  getUserInvitations: (projectUuid) =>
    request(`/users/current/invitations?projectUuid=${projectUuid}`, { method: 'GET' }),
  getVizitShareLink: (vizitUuid) => request(`/users/current/vizits/${vizitUuid}/share`, { method: 'GET' }),
  getIsAllowedNetworking: (projectUuid) =>
    request(`/users/current/is-allowed-networking?${queryString.stringify({ projectUuid })}`, { method: 'GET' }),
  distributeOrders: (body) => request(`/users/current/orders/distribute`, { method: 'POST', body }),
  getRoleAccesses: (projectUuid) => {
    // return fetch('/visibles.json').then((res) => res.json());
    return request(`/users/current/projects/${projectUuid}/role-access`, { method: 'GET' });
  },
};
