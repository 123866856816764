import { Close, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import IconifyIcon from 'src/@core/components/icon';
import { useLanguage } from 'src/hooks';
import BookingCatalog from './BookingCatalog';
import { useSearchParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import SpoilerInfo from './SpoilerInfo';
import { useSelector } from 'react-redux';

const BookingPage = ({ projectUuid, typeUuid }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentTab, setCurrentTab] = useState(
    ['0', '1'].includes(searchParams.get('tab')) ? +searchParams.get('tab') : 0
  );
  const [searchStr, setSearchStr] = useState('');

  const hints = useSelector((state) => state.app.currentProject?.accesses?.hints) ?? {};

  const { lang, localizeMessage, localizeText, getLocalizationValue } = useLanguage();

  useEffect(() => {
    searchParams.set('tab', currentTab);
    setSearchParams(searchParams);
  }, [currentTab]);

  return (
    <Stack gap="1rem" maxWidth={'1300px'} width={'100%'} alignItems="center" alignSelf="center">
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        sx={{
          marginTop: '-10px',
          boxShadow: '0px 6px 16px -4px #3A35411A, 0px 2px 12px -4px #3A354114, 0px 2px 12px -4px #3A35411A',
          backgroundColor: 'white',
          padding: '10px',
        }}
      >
        <Stack direction="row" gap="1rem">
          <Button
            onClick={() => {
              setCurrentTab(0);
            }}
            variant={currentTab === 0 ? 'contained' : 'outlined'}
          >
            {localizeText.BOOKING_TAB_CATALOG}
          </Button>
          <Button
            onClick={() => {
              setCurrentTab(1);
            }}
            variant={currentTab === 1 ? 'contained' : 'outlined'}
          >
            {localizeText.BOOKING_TAB_HISTORY}
          </Button>
        </Stack>
        <Stack position="relative" sx={{ pl: '1rem', flex: 1, maxWidth: '400px' }}>
          <TextField
            size="small"
            value={searchStr}
            onChange={(event) => setSearchStr(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                  <IconifyIcon icon="mdi:magnify" />
                </InputAdornment>
              ),
              endAdornment: searchStr ? (
                <InputAdornment position="end" onClick={() => setSearchStr('')}>
                  <IconButton size="small">
                    <IconifyIcon icon="mdi:close" fontSize={20} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Stack>
      </Stack>
      {hints.bookingHint && (
        <SpoilerInfo
          projectUuid={projectUuid}
          typeUuid={typeUuid}
          defaultText={getLocalizationValue(hints, 'bookingHintText')}
        />
      )}

      <BookingCatalog searchStr={searchStr} activeTab={currentTab} typeUuid={typeUuid} />
    </Stack>
  );
};

export default BookingPage;
