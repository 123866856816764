import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ChangePasswordForm,
  CompanyForm,
  ConfirmModal,
  MainLayout,
  MainProfile,
  ViewImageModal,
} from '../../components';
import { PassportForm } from '../../components/passport-form';
import { ProfileForm } from '../../components/profile-form';
import { getCurrentUserCompany } from '../../store/actions/auth';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import classes from './profile.module.sass';

const ProfilePage = () => {
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const breadcrumbs = [
    { path: '/', title: TEXT[lang].MAIN_PAGE },
    { path: null, title: TEXT[lang].PROFILE },
  ];

  const company = useSelector((state) => state.auth.company);
  const [showAddCompany, setShowAddCompany] = useState(false);

  // const handleForceEdit = () => {
  //   eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
  //     title: TEXT[lang].CONFIRM,
  //     content: `${MESSAGES[lang].FORCE_EDIT_CONFIRM}`,
  //     onOk: () => {
  //       updateUser({ forceEdit: true });
  //     },
  //   });
  // };

  useEffect(() => {
    getCurrentUserCompany();
  }, []);

  return (
    <MainLayout breadcrumbs={breadcrumbs}>
      <React.Fragment>
        <div className={classes.profileTop}>
          <MainProfile
            showChangePassword={true}
            // extraRightElement={
            //   !company && (
            //     <Button
            //       className={classes.addCompany}
            //       sx={{ borderRadius: '4px !important' }}
            //       onClick={() => setShowAddCompany(!showAddCompany)}
            //     >
            //       {TEXT[lang].ADD_COMPANY}
            //     </Button>
            //   )
            // }
          />
        </div>

        <div className={classes.formGroup}>
          <ProfileForm user={user} />
          {/* <PassportForm /> */}
        </div>
        {(company || showAddCompany) && <CompanyForm />}
      </React.Fragment>
      <ConfirmModal />
      <ViewImageModal />
      <ChangePasswordForm />
    </MainLayout>
  );
};

export default ProfilePage;
