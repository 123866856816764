import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getWordEvent } from '../../utils/common';
import { STANDARD_DATE_FORMAT } from '../../utils/constant';
import classes from './project-bar.module.sass';
import DatePicker from '../date-picker';
import { Clear, Padding } from '@mui/icons-material';
import CloseIcon from 'src/icons/close';
import { RIGHT } from 'react-swipeable';
import select from 'src/theme/overrides/select';
import { useSelector } from 'react-redux';

export const ProjectBar = ({ loading, allProjects = [], planSince, onChangeFilter = () => {} }) => {
  const user = useSelector((state) => state.auth.user);
  const handleFilters = ({ planSince, isArchive, selectedMonth, participating, searchDebouncedQuery }) => {
    const startDate = moment.unix(planSince).startOf('month').unix() + 36000;
    const endDate = moment.unix(planSince).endOf('month').unix() + 36000;

    onChangeFilter(
      {
        isArchived: isArchive,
        query: searchDebouncedQuery,
        lkMonth: selectedMonth === '' ? undefined : selectedMonth,
        participating: participating ? true : undefined,
        userUuid: user?.uuid,
      },
      { selectedMonth }
    );
  };

  const reset = () => {
    setIsArchive(false);
    setIsAcreditated(false);
    setIsExpo(false);
    setIsGuest(false);
    setIsOnline(false);
    setParticipating(false);
    setSearchQuery('');
    setSearchDebouncedQuery('');
    setSelectedMonth(undefined);
    setSelectedOrg('');
  };

  const [isArchive, setIsArchive] = useState(false);
  const [isAcreditated, setIsAcreditated] = useState(false);
  const [isExpo, setIsExpo] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [participating, setParticipating] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchDebouncedQuery, setSearchDebouncedQuery] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [selectedOrg, setSelectedOrg] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchDebouncedQuery(searchQuery);
    }, 400);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    handleFilters({
      planSince,
      isExpo,
      isGuest,
      isOnline,
      isAcreditated,
      isArchive,
      searchDebouncedQuery,
      selectedMonth,
      selectedOrg,
      participating,
    });
  }, [
    allProjects,
    isExpo,
    isGuest,
    isOnline,
    isAcreditated,
    isArchive,
    searchDebouncedQuery,
    selectedMonth,
    selectedOrg,
    participating,
    selectedMonth,
  ]);

  return (
    <Box noam className={classes.root}>
      <Stack
        className={classes.firstRow}
        flexDirection={'row'}
        spacing={2}
        margin={1}
        gap={2}
        alignItems={'self-end'}
        justifyContent={'center'}
        flexWrap={'wrap'}
      >
        <TextField
          size="small"
          id="outlined"
          variant="outlined"
          className={classes.searchBox}
          InputLabelProps={{
            sx: {
              left: '28px',
              maxWidth: '80%',
            },
            shrink: false,
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          label={searchQuery ? '' : 'Введите название проекта или ключевые слова'}
          InputProps={{
            sx: {
              minWidth: '215px',
            },
            startAdornment: (
              <InputAdornment sx={{ paddingRight: '5px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                  <path
                    d="M18.6884 8.93619C18.6884 10.9082 18.019 12.7298 16.8915 14.2077L22.5788 19.651C23.1404 20.1881 23.1404 21.0602 22.5788 21.5972C22.0173 22.1343 21.1053 22.1343 20.5438 21.5972L14.8564 16.1539C13.311 17.2365 11.4062 17.8724 9.34421 17.8724C4.18243 17.8724 0 13.8726 0 8.93619C0 3.9998 4.18243 0 9.34421 0C14.506 0 18.6884 3.9998 18.6884 8.93619ZM9.34421 15.1228C10.1937 15.1228 11.0349 14.9628 11.8198 14.6519C12.6047 14.341 13.3178 13.8853 13.9185 13.3108C14.5192 12.7363 14.9957 12.0543 15.3208 11.3037C15.6459 10.5531 15.8133 9.74863 15.8133 8.93619C15.8133 8.12376 15.6459 7.31928 15.3208 6.56868C14.9957 5.81809 14.5192 5.13609 13.9185 4.56161C13.3178 3.98713 12.6047 3.53143 11.8198 3.22052C11.0349 2.90962 10.1937 2.7496 9.34421 2.7496C8.49468 2.7496 7.65347 2.90962 6.8686 3.22052C6.08374 3.53143 5.37059 3.98713 4.76989 4.56161C4.16918 5.13609 3.69267 5.81809 3.36757 6.56868C3.04247 7.31928 2.87514 8.12376 2.87514 8.93619C2.87514 9.74863 3.04247 10.5531 3.36757 11.3037C3.69267 12.0543 4.16918 12.7363 4.76989 13.3108C5.37059 13.8853 6.08374 14.341 6.8686 14.6519C7.65347 14.9628 8.49468 15.1228 9.34421 15.1228Z"
                    fill="#D2D2D2"
                  />
                </svg>
              </InputAdornment>
            ),
          }}
          sx={{
            '.MuiOutlinedInput-root': {
              borderRadius: 5,
              height: '37px',
              minWidth: '225px',
            },
            minWidth: '225px',
            flexGrow: 1,
          }}
        />
        <Button size="small" variant="outlined" sx={{ width: '113px' }} onClick={() => reset()}>
          Сбросить
        </Button>
        <Button
          size="small"
          onClick={() => setIsArchive(!isArchive)}
          variant="outlined"
          sx={
            isArchive
              ? {
                  width: '113px',
                  backgroundColor: '#737791',
                  color: 'white !important',
                }
              : {
                  width: '113px',
                }
          }
        >
          Архив
        </Button>
      </Stack>
      <Stack
        flexDirection={'row'}
        spacing={2}
        margin={1}
        gap={2}
        alignItems={'baseline'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Select
          size="small"
          className={classes.searchSelect}
          sx={{
            minWidth: '170px',
            borderRadius: 50,
            '& .MuiSelect-select span::before': {
              content: "'Месяц'",
            },
            '.MuiOutlinedInput-root': {
              borderRadius: 5,
            },
            textTransform: 'Capitalize',
          }}
          style={{ height: 37 }}
          onChange={(e) => {
            setSelectedMonth(e.target.value);
          }}
          value={selectedMonth ?? ''}
          IconComponent={
            selectedMonth != ''
              ? () => (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setSelectedMonth('');
                    }}
                    sx={{ marginRight: '3px' }}
                  >
                    <Clear />
                  </IconButton>
                )
              : undefined
          }
        >
          {moment
            .localeData()
            .months()
            .map((month, index) => (
              <MenuItem key={index} value={index + 1} sx={{ textTransform: 'Capitalize' }}>
                {month}
              </MenuItem>
            ))}
        </Select>
        {/* <Select
          size="small"
          className={classes.searchSelect}
          sx={{
            minWidth: '170px',
            borderRadius: 50,
            '& .MuiSelect-select span::before': {
              content: "'Организатор'",
            },
            '.MuiOutlinedInput-root': {
              borderRadius: 5,
            },
          }}
          style={{ height: 37 }}
          onChange={(e) => {
            setSelectedOrg(e.target.value);
          }}
          value={selectedOrg}
          IconComponent={
            selectedOrg != ''
              ? () => (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setSelectedOrg('');
                    }}
                    sx={{ marginRight: '3px' }}
                  >
                    <Clear />
                  </IconButton>
                )
              : undefined
          }
        >
          <MenuItem value={10}>Первый</MenuItem>
          <MenuItem value={20}>Второй</MenuItem>
          <MenuItem value={30}>Третий</MenuItem>
        </Select> */}
        <Button
          size="small"
          variant="outlined"
          onClick={() => setParticipating(!participating)}
          sx={
            participating
              ? {
                  borderColor: '#1B88FF !important',
                  color: 'white  !important',
                  bgcolor: '#1B88FF !important',
                  minWidth: '157px',
                }
              : {
                  borderColor: '#1B88FF !important',
                  color: '#1B88FF  !important',
                  minWidth: '157px',
                }
          }
        >
          Я участвую
        </Button>
        {/* <Button
          size="small"
          variant="outlined"
          onClick={() => setParticipating(!participating)}
          sx={
            isGuest
              ? {
                  borderColor: 'rgba(255, 130, 65, 1) !important',
                  color: 'white  !important',
                  bgcolor: 'rgba(255, 130, 65, 1) !important',
                  minWidth: '157px',
                }
              : {
                  borderColor: 'rgba(255, 130, 65, 1) !important',
                  color: 'rgba(255, 130, 65, 1)  !important',
                  minWidth: '157px',
                }
          }
        >
          Гостевые
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setIsExpo(!isExpo)}
          sx={
            isExpo
              ? {
                  borderColor: 'rgba(21, 195, 149, 1) !important',
                  color: 'white  !important',
                  bgcolor: 'rgba(21, 195, 149, 1) !important',
                  minWidth: '157px',
                }
              : {
                  borderColor: 'rgba(21, 195, 149, 1) !important',
                  color: 'rgba(21, 195, 149, 1)  !important',
                  minWidth: '157px',
                }
          }
        >
          Экспофорум
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setIsOnline(!isOnline)}
          sx={
            isOnline
              ? {
                  borderColor: 'rgba(255, 118, 164, 1) !important',
                  color: 'white  !important',
                  bgcolor: 'rgba(255, 118, 164, 1) !important',
                  minWidth: '157px',
                }
              : {
                  borderColor: 'rgba(255, 118, 164, 1) !important',
                  color: 'rgba(255, 118, 164, 1)  !important',
                  minWidth: '157px',
                }
          }
        >
          Онлайн
        </Button> */}
      </Stack>
    </Box>
  );
};
