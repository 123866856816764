import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import AddNewMember from '../AddNewMember';
import { Close } from '@mui/icons-material';

function CreateMemberModal({
  projectUuid,
  rolesById,
  userInvitations,
  fetchUserInvitations,
  refetchMembersOfProject,
  setCreatedUser,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleShowAdd = () => {
      setOpen(true);
    };

    eventBus.on(EVENTS.OPEN_ADD_MEMBER_IN_MEMBER_TABLE, handleShowAdd);
    return () => {
      eventBus.off(EVENTS.OPEN_ADD_MEMBER_IN_MEMBER_TABLE, handleShowAdd);
    };
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Добавить нового участника
        <IconButton sx={{ position: 'absolute', top: '1rem', right: '1rem' }} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ '&>form': { width: '100%', minWidth: { xs: '100%', sm: '500px', md: '500px' } } }}>
          <AddNewMember
            projectUuid={projectUuid}
            rolesById={rolesById}
            setShowAddNew={() => handleClose()}
            userInvitations={userInvitations}
            fetchUserInvitations={fetchUserInvitations}
            refetchMembersOfProject={refetchMembersOfProject}
            setCreatedUser={setCreatedUser}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CreateMemberModal;
