import {
  Button,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { updateUser } from '../../store/actions/auth';
import { getFullName } from '../../utils/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { filterUnChangedFields, profileValidationSchema } from '../../utils/formValidator';
import classes from './profile-form.module.sass';
import { GENDER_OPTIONS, LOCALIZATION, TEXT } from '../../utils/constant';
import { userAPIs } from '../../services';

let isTyping;
export function ProfileForm({ user, isLocked }) {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        // city: user.city,
        email: user.email,
        phone: user.phone,
        // gender: user.gender,
      }),
      [user]
    ),
    resolver: yupResolver(profileValidationSchema(lang)),
  });

  const [focusingField, setFocusingField] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  // const fullNameRef = useRef(null);
  const addressRef = useRef(null);

  const getSuggestion = async (query, field = '', isAddress) => {
    if (isSearching) return;
    try {
      setIsSearching(true);
      const res = isAddress ? await userAPIs.getSuggestionAddress(query) : await userAPIs.getSuggestionFullName(query);
      setSuggestions(res.message || []);
      setFocusingField(field);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSearching(false);
    }
  };

  // const renderSuggestion = useCallback(
  //   (field, ref, isAddress) => {
  //     const handleSelect = (suggestion) => {
  //       setFocusingField('');
  //       if (isAddress) {
  //         suggestion && setValue('city', suggestion);
  //       } else {
  //         suggestion.fullName && setValue('fullname', suggestion.fullName);
  //         if (suggestion.gender) {
  //           let suggestionGender = suggestion.gender || '';
  //           if (suggestionGender === 'MALE') {
  //             setValue('gender', Object.keys(GENDER_OPTIONS)[0]);
  //           } else if (suggestionGender === 'FEMALE') {
  //             setValue('gender', Object.keys(GENDER_OPTIONS)[1]);
  //           } else {
  //             setValue('gender', Object.keys(GENDER_OPTIONS)[2]);
  //           }
  //         }
  //       }
  //     };

  //     return (
  //       <Popper
  //         open={focusingField === field && suggestions?.length > 0}
  //         anchorEl={ref.current}
  //         placement="bottom-start"
  //         transition
  //         sx={{ zIndex: 9999 }}
  //       >
  //         {({ TransitionProps, placement }) => (
  //           <Grow
  //             {...TransitionProps}
  //             style={{
  //               transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
  //             }}
  //           >
  //             <Paper className={classes.suggestions}>
  //               <ClickAwayListener onClickAway={() => setFocusingField('')}>
  //                 <MenuList
  //                   sx={{
  //                     maxWidth: '50vw',
  //                     maxHeight: '450px',
  //                     overflow: 'scroll',
  //                   }}
  //                 >
  //                   {suggestions.map((suggest, i) => (
  //                     <MenuItem key={i} onClick={() => handleSelect(suggest)}>
  //                       {isAddress ? `${suggest}` : `${suggest.fullName} - ${suggest.gender}`}
  //                     </MenuItem>
  //                   ))}
  //                 </MenuList>
  //               </ClickAwayListener>
  //             </Paper>
  //           </Grow>
  //         )}
  //       </Popper>
  //     );
  //   },
  //   [suggestions, focusingField]
  // );

  const handleChangeTextField = (e, field, isAddress) => {
    const value = e.target.value;
    setValue(field, value);
    clearTimeout(isTyping);
    isTyping = setTimeout(() => {
      getSuggestion(value, field, isAddress);
    }, 1000);
  };

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const body = filterUnChangedFields({ ...user, fullname: getFullName(user) }, values);
      const newProfile = await updateUser(body);
      if (newProfile) {
        setValue('fullname', getFullName(newProfile));
        setValue('city', newProfile.city);
        setValue('email', newProfile.email);
        setValue('phone', newProfile.phone);
      }
      setIsChanged(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const subscription = watch(() => setIsChanged(true));
    return () => subscription.unsubscribe();
  }, [watch]);

  const labelStyle = { fontSize: 17 };

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Typography className={classes.title}>{TEXT[lang].PROFILE}:</Typography>
      <div>
        <TextField
          required
          label={TEXT[lang].LAST_NAME}
          margin="normal"
          InputLabelProps={{
            shrink: true,
            sx: {
              '& .MuiInputLabel-asterisk': {
                color: 'red',
              },
            },
            style: labelStyle,
          }}
          InputProps={{
            sx: {
              '& input': {
                textTransform: 'capitalize',
              },
            },
          }}
          //inputRef={fullNameRef}
          sx={{ width: '100%' }}
          variant="standard"
          defaultValue={getValues('lastName')}
          {...register('lastName')}
          onChange={(e) => handleChangeTextField(e, 'lastName')}
          error={errors.lastName ? true : false}
          helperText={errors.lastName?.message}
          disabled={isLocked}
        />
        <TextField
          required
          label={TEXT[lang].FIRST_NAME}
          margin="normal"
          InputLabelProps={{
            shrink: true,
            sx: {
              '& .MuiInputLabel-asterisk': {
                color: 'red',
              },
            },
            style: labelStyle,
          }}
          InputProps={{
            sx: {
              '& input': {
                textTransform: 'capitalize',
              },
            },
          }}
          //inputRef={fullNameRef}
          sx={{ width: '100%' }}
          variant="standard"
          defaultValue={getValues('firstName')}
          {...register('firstName')}
          onChange={(e) => handleChangeTextField(e, 'firstName')}
          error={errors.firstName ? true : false}
          helperText={errors.firstName?.message}
          disabled={isLocked}
        />
        <TextField
          required
          label={TEXT[lang].MIDDLE_NAME}
          margin="normal"
          InputLabelProps={{
            shrink: true,
            sx: {
              '& .MuiInputLabel-asterisk': {
                color: 'red',
              },
            },
            style: labelStyle,
          }}
          InputProps={{
            sx: {
              '& input': {
                textTransform: 'capitalize',
              },
            },
          }}
          //inputRef={fullNameRef}
          sx={{ width: '100%' }}
          variant="standard"
          defaultValue={getValues('middleName')}
          {...register('middleName')}
          onChange={(e) => handleChangeTextField(e, 'middleName')}
          error={errors.middleName ? true : false}
          helperText={errors.middleName?.message}
          disabled={isLocked}
        />
        {/* {renderSuggestion('fullname', fullNameRef)} */}
      </div>

      {/* <div>
        <TextField
          required
          label={TEXT[lang].PLACE_OF_LIVING}
          margin="normal"
          InputLabelProps={{
            shrink: true,
            sx: {
              '& .MuiInputLabel-asterisk': {
                color: 'red',
              },
            },
            style: labelStyle,
          }}
          inputRef={addressRef}
          sx={{ width: '100%' }}
          variant="standard"
          defaultValue={getValues('city')}
          {...register('city')}
          onChange={(e) => handleChangeTextField(e, 'city', true)}
          error={errors.city ? true : false}
          helperText={errors.city?.message}
          disabled={isLocked}
        />
        {renderSuggestion('city', addressRef, true)}
      </div> */}

      {/* <FormControl margin="normal" error={errors.gender ? true : false} disabled={isLocked}>
        <InputLabel
          required
          shrink={true}
          variant="standard"
          sx={{
            '& .MuiInputLabel-asterisk': {
              color: 'red',
            },
            fontSize: 17,
          }}
        >
          {TEXT[lang].GENDER}
        </InputLabel>

        <Select
          variant="standard"
          margin="dense"
          defaultValue={getValues('gender') || ''}
          {...register('gender')}
          value={getValues('gender') || ''}
        >
          {Object.keys(GENDER_OPTIONS).map((gender) => (
            <MenuItem key={gender} value={gender}>
              {GENDER_OPTIONS[gender][lang]}
            </MenuItem>
          ))}
        </Select>
        {errors.gender && (
          <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
            {errors.gender.message}
          </FormHelperText>
        )}
      </FormControl> */}
      <TextField
        required
        label={TEXT[lang].NUMBER_TELEPHONE}
        margin="normal"
        InputLabelProps={{
          shrink: true,
          sx: {
            '& .MuiInputLabel-asterisk': {
              color: 'red',
            },
          },
          style: labelStyle,
        }}
        variant="standard"
        defaultValue={getValues('phone')}
        {...register('phone')}
        error={errors.phone ? true : false}
        helperText={errors.phone?.message}
        disabled={isLocked}
      />
      <div className={classes.button}>
        <Button
          type="submit"
          className={classes.submitButton}
          variant="contained"
          size="large"
          disabled={isSubmitting || !isChanged || isLocked}
        >
          {TEXT[lang].SAVE_CHANGES}
        </Button>
      </div>
    </form>
  );
}
