import { Card } from '@mui/material';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProjectsSidebarFilters from 'src/components/molecules/projects/SidebarFilters';
import { ConfirmModal, JoinedProject, MainLayout, ProjectBar, ProjectList, ViewImageModal } from '../../components';
import { getProjectsList, setCurrentProject } from '../../store/actions/app';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import classes from './projects.module.sass';

const ProjectsPage = () => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const breadcrumbs = [{ path: null, title: 'Мероприятия' || TEXT[lang].MAIN_PAGE }];
  const [projects, setProjects] = useState([]); //Отфильтрованные проекты

  const [allProjects, setAllProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    limit: 100,
    offset: 0,
    // plan_since: moment().startOf('d').add(-100, 'months').unix(),
    // plan_till: moment().startOf('d').add(100, 'months').unix(),
    lk: true,
    isArchived: false,
    query: '',
    number_of_participants: undefined,
  });

  const handleChangeFilter = async (newFilters, clientProps) => {
    // alert(JSON.stringify(newFilters));
    const _filters = { ...filters, ...newFilters };
    setFilters(_filters);
    const res = await getProjectsList(queryString.stringify(_filters), false);
    setProjects(res);
  };

  useEffect(() => {
    setCurrentProject(null);

    const fetchData = async () => {
      const res = await getProjectsList(queryString.stringify(filters), false);
      setAllProjects(res);
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSidebarContent = () => {
    return <ProjectsSidebarFilters filters={filters} onChangeFilters={handleChangeFilter} />;
  };

  return (
    <MainLayout breadcrumbs={breadcrumbs} renderSidebarContent={renderSidebarContent}>
      {/* {!loading && <JoinedProject />} */}

      <ProjectBar
        loading={loading}
        planSince={filters.plan_since}
        allProjects={allProjects}
        onChangeFilter={handleChangeFilter}
      />
      {!loading && <ProjectList projects={projects} />}
      <ConfirmModal />
      <ViewImageModal />
    </MainLayout>
  );
};

export default ProjectsPage;
