import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowForward } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Button as MuiButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthLayout, Button, ForgotPasswordForm } from 'src/components';
import { useLanguage } from 'src/hooks';
import YandexIcon from 'src/icons/yandex';
import { authAPIs } from 'src/services';
import { login, updateIsNewAccount } from 'src/store/actions/auth';
import { MESSAGES } from 'src/utils/constant';
import { loginValidationSchema, loginWithoutPasswordValidationSchema } from 'src/utils/formValidator';
import { createNotification } from 'src/utils/notifications';
import { routes } from 'src/utils/routes';
import classes from './login.module.sass';

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { lang, localizeMessage, localizeText } = useLanguage();

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCheckedEmail, setIsCheckedEmail] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showLockLogin, setShowLockLogin] = useState(false);

  const { projectUuid, roleUuid, invitation_id } = queryString.parse(location.search);

  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(isCheckedEmail ? loginValidationSchema(lang) : loginWithoutPasswordValidationSchema(lang)),
  });

  const onSubmit = async ({ email, password }) => {
    try {
      setIsSubmitting(true);
      setShowWarning(false);
      setShowLockLogin(false);

      if (!isCheckedEmail) {
        try {
          await authAPIs.checkEmail({ email });
          //Есть ли имейл в системе
          setIsCheckedEmail(true);
        } catch (e) {
          console.log(e);
          // Если имейл не найден в системе переходим на регистрацию
          // navigate(routes.register.path + location.search, { state: { email, fromLogin: true } });
          window.location.href = process.env.REACT_APP_REG_URL + `/?email=${encodeURIComponent(email)}`;
        }
      }
      if (email && password) {
        const res = await login({ email, password, projectUuid, roleUuid, invitationUuid: invitation_id }, true);
        if (res && projectUuid) {
          updateIsNewAccount({ isNewAccount: true, newProjectUuid: projectUuid });
        }
        if (res?.code === 400) setShowWarning(true);
        if (res?.code === 429) setShowLockLogin(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLoginWithYandex = async () => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      window.open(`${process.env.REACT_APP_SERVER_URL}/auth/yandex`, '_self');
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    unregister('password');
  }, [unregister]);

  useEffect(() => {
    if (location.state && location.state.message) {
      createNotification(location.state.message);
    }
  }, [location]);

  return (
    <AuthLayout>
      <div className={classes.root}>
        <div className={classes.titleWrapper}>
          <h1 className={classes.title}>{localizeText.PERSONAL_AREA}</h1>
          <h2 className={classes.subTitle}>{localizeMessage.EVENT_PARTICIPANT}</h2>
          <span className={classes.description}>
            {localizeMessage.TO_REGISTER_FOR_THE_EVENT}
            <br />
            {localizeMessage.OR_REGISTER_YOUR_ACCOUNT}
          </span>
        </div>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label={localizeMessage.ENTER_YOUR_EMAIL}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            autoFocus
            variant="standard"
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
          />

          {isCheckedEmail && (
            <Stack>
              <TextField
                label={localizeText.PASSWORD}
                type={showPassword ? 'text' : 'password'}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                {...register('password')}
                error={errors.password ? true : false}
                helperText={errors.password?.message}
              />
              <Typography
                sx={{
                  color: (theme) =>
                    showWarning ? theme.palette.warning.main : showLockLogin ? theme.palette.error.main : '',
                }}
              >
                {showWarning ? MESSAGES[lang].LOGIN_WARNING : showLockLogin ? MESSAGES[lang].LOGIN_ERROR : ''}
              </Typography>
            </Stack>
          )}

          <Stack direction="row" gap="24px" justifyContent="space-between">
            <Link href={routes.forgotPassword.path} underline="none" sx={{ color: '#141414', fontWeight: 500 }}>
              {localizeText.FORGOT_PASSWORD} ?
            </Link>

            <Button
              type="submit"
              className={classes.submitButton}
              endIcon={<ArrowForward />}
              disabled={isSubmitting || showLockLogin}
            >
              {localizeText.FURTHER}
            </Button>
          </Stack>

          <Box className={classes.loginOr}>
            <hr />
            <span>{localizeText.OR}</span>
          </Box>
          <Stack direction={{ xs: 'column', md: 'row' }} gap="24px" justifyContent="space-between" alignItems="center">
            <MuiButton
              className={classes.loginYandex}
              startIcon={<YandexIcon height={24} width={24} />}
              onClick={handleLoginWithYandex}
            >
              {localizeMessage.LOGIN_WITH_YANDEX_ID}
            </MuiButton>

            <Stack direction="row" alignItems="center" gap="1ch">
              <span style={{ fontWeight: 300 }}>{localizeText.ALREADY_HAVE_ACCOUNT}? </span>

              <Link
                onClick={() => navigate(routes.register.path + location.search, { state: { fromLogin: true } })}
                underline="none"
                sx={{ color: '#141414', fontWeight: 500, cursor: 'pointer' }}
              >
                {localizeText.AUTHORIZATION}
              </Link>
            </Stack>
          </Stack>
        </form>

        <ForgotPasswordForm />
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
