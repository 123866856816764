import {
  AutoStories,
  Badge,
  BookOnline,
  ContentPaste,
  FmdGood,
  HomeRepairService,
  People,
  PeopleAltTwoTone,
  JoinFullRounded,
  PersonOutline,
  CalendarMonth,
  PhoneIphoneSharp,
  InstallMobile,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from 'src/hooks';
import { routes } from 'src/utils/routes';
import './style.sass';
import { JoinedProject } from 'src/components/joined-project';

function Sidebar({ callback }) {
  const navigate = useNavigate();
  const { projectUuid } = useParams();
  const location = useLocation();
  const { localizeText, getLocalizationValue } = useLanguage();

  const projectSettings = useSelector((state) => state.app.projectSettings);
  const roles = useSelector((state) => state.app.roles);
  const projects = useSelector((state) => state.app.projects);
  const currentUser = useSelector((state) => state.auth.user);
  const mainRoleId = currentUser?.projectSettings?.[projectUuid]?.mainRoleId;
  const joinedProjects = currentUser?.projectUuids?.includes(projectUuid);

  const sideMenuAccesses = useSelector((state) => state.app.currentProject?.accesses?.sideMenu) ?? {};
  var typeProcessBDK = projectSettings?.typeProcessBDK;
  const iconSize = { height: '24px', width: '24px' };
  const PROJECT_DETAIL_TABS = useMemo(() => {
    console.log('PROJECT_DETAIL_TABS', sideMenuAccesses);
    return {
      PROJECTDASHBOARD: {
        key: 'projectdashboard',
        title: 'Главная',
        icon: <FmdGood sx={{ ...iconSize }} />,
        path: routes.projectDashboard.path.replace(':projectUuid', projectUuid),
      },
      ABOUT: {
        key: 'about',
        title: 'О мероприятии',
        icon: <FmdGood sx={{ ...iconSize }} />,
        path: routes.projectDetail.path.replace(':projectUuid', projectUuid),
        isHidden: !sideMenuAccesses.about,
      },
      CALENDARS: {
        key: 'calendars',
        icon: <CalendarMonth sx={{ ...iconSize }} />,
        title: 'Мой календарь',
        path: routes.calendar.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects || !sideMenuAccesses.calendars,
      },
      ACCREDITATION: {
        key: 'accreadition',
        title: 'Моя анкета',
        icon: <PersonOutline sx={{ ...iconSize }} />,
        path: routes.projectAccreadition.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects || !sideMenuAccesses.accreditation,
      },
      PARTICIPANTS: {
        key: 'participants',
        title: 'Мой список участников',
        icon: <People sx={{ ...iconSize }} />,
        path: routes.projectAccreadition.path.replace(':projectUuid', projectUuid),
        search: 'tab=members',
        isHidden: !joinedProjects || !sideMenuAccesses.participants,
      },
      BUSINESS_PROGRAMS: {
        key: 'business-programs',
        title: 'Деловая программа',
        icon: <ContentPaste sx={{ ...iconSize }} />,
        path: routes.projectBusinessProgram.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects || !sideMenuAccesses.businessProgram,
      },
      EXPONENTS: {
        key: 'Exponents',
        title: 'Экспоненты',
        icon: <PeopleAltTwoTone sx={{ ...iconSize }} />,
        path: getLocalizationValue(sideMenuAccesses, 'exponentsLink'),
        isHidden: !joinedProjects || !sideMenuAccesses.exponents,
      },
      BUY_TICKET: {
        key: 'buy-ticket',
        title: 'Пакеты услуг участника',
        icon: <HomeRepairService sx={{ ...iconSize }} />,
        path: routes.projectBuyTicket.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects || !sideMenuAccesses.buyTicket,
      },
      FILES: {
        key: 'files',
        title: 'Информационные материалы',
        icon: <AutoStories sx={{ ...iconSize }} />,
        path: routes.projectInfoFiles.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects || !sideMenuAccesses.files,
      },
      ORDERS: {
        key: 'orders',
        title: localizeText.ORDERS,
        icon: <Badge sx={{ ...iconSize }} />,
        path: routes.myOrder.path.replace(':projectUuid', projectUuid),
        isHidden: !joinedProjects || !sideMenuAccesses.orders,
      },
      BDK: {
        key: 'bdk',
        title: getLocalizationValue(sideMenuAccesses, 'bdkName') || localizeText.BDK,
        icon: <Badge sx={{ ...iconSize }} />,
        path: routes.projectVizits.path.replace(':projectUuid', projectUuid),
        isHidden:
          !joinedProjects || !projects.find((p) => p.uuid === projectUuid)?.modules.BDK || !sideMenuAccesses.bdk,
      },
      BOOKING: {
        key: 'booking',
        title: localizeText.BOOKING_TITLE,
        icon: <BookOnline sx={{ ...iconSize }} />,
        path: routes.projectBooking.path.replace(':projectUuid', projectUuid),
        search: 'tab',
        isHidden: !joinedProjects || !sideMenuAccesses.booking,
      },
      MOBILE_APP: {
        key: 'mobile_app',
        title: localizeText.MOBILE_APP,
        icon: <InstallMobile sx={{ ...iconSize }} />,
        path: getLocalizationValue(sideMenuAccesses, 'appLink'),
        isHidden: !joinedProjects || !sideMenuAccesses.appDownload,
      },
    };
  }, [projectUuid, localizeText, joinedProjects, sideMenuAccesses]);

  const handleNavigate = (path) => () => {
    if (path?.startsWith('http')) {
      window.open(path, '_blank');
      return;
    }
    navigate(path);
    callback?.();
  };

  return projectUuid ? (
    <Stack className="sidebar" gap="8px">
      {Object.values(PROJECT_DETAIL_TABS).map((tab) => {
        const isActive = tab.search
          ? location?.pathname === tab.path && location.search?.includes(tab.search)
          : location?.pathname === tab.path && !location.search;

        return (
          !tab.isHidden && (
            <div
              className={classNames('sidebar__tab', { selected: isActive })}
              key={tab.key}
              onClick={handleNavigate(tab.path + (tab.search ? `?${tab.search}` : ''))}
            >
              <span className="sidebar__tab-icon">{tab.icon}</span>
              <span className="sidebar__tab-title">{tab.title}</span>
            </div>
          )
        );
      })}
    </Stack>
  ) : (
    <></>
  );
}

export default Sidebar;
