import { Chip, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { LOCALIZATION } from 'src/utils/constant';

const STATUS = {
  [LOCALIZATION.en_US]: {
    REJECTED: 'Rejected',
    ACCREDITED: 'Accredited',
    FOR_VERIFICATION: 'For verification',
    NOT_READY: 'Not ready',
  },
  [LOCALIZATION.ru_RU]: {
    REJECTED: 'Отклонено',
    ACCREDITED: 'Аккредитованы',
    FOR_VERIFICATION: 'На проверке',
    NOT_READY: 'Заполните анкету',
  },
};

const getStatusLabel = (isAccredited, readyToAccreditate, lang) => {
  switch (isAccredited) {
    case false:
      return STATUS[lang].REJECTED;
    case true:
      return STATUS[lang].ACCREDITED;
    case null:
    case undefined:
    default:
      switch (readyToAccreditate) {
        case true:
          return STATUS[lang].FOR_VERIFICATION;
        default:
          return STATUS[lang].NOT_READY;
      }
  }
};

const getStatusColor = (isAccredited, readyToAccreditate) => {
  switch (isAccredited) {
    case false:
      return 'error';
    case true:
      return 'success';
    case null:
    case undefined:
    default:
      switch (readyToAccreditate) {
        case true:
          return 'warning';
        default:
          return 'info';
      }
  }
};

export default function UserStatus({ isAccredited, readyToAccreditate, sx, icon, onClick, project }) {
  const lang = useSelector((state) => state.app.lang);
  const label = getStatusLabel(isAccredited, readyToAccreditate, lang);
  const color = getStatusColor(isAccredited, readyToAccreditate);
  const user = useSelector((state) => state.auth.user);
  const isJoinedProject = user?.projectUuids?.includes(project);

  return (
    <>
      <Tooltip title={isJoinedProject ? 'Перейти к анкете' : 'Сперва нажмите на " Добавить в мой список"'}>
        <Chip
          sx={{
            ...sx,
            '& .MuiChip-deleteIcon': {
              display: 'none',
              color: '#333',
            },
            '& .MuiChip-deleteIcon:hover': {
              color: '#333',
              display: 'none',
            },
          }}
          label={label}
          skin="light"
          color={color}
          onClick={onClick}
          onDelete={() => {}}
          // deleteIcon={icon}
        />
      </Tooltip>
    </>
  );
}
