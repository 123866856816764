import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { userAPIs } from 'src/services';
import { getProjectsList, setCurrentProject } from 'src/store/actions/app';

export function useCurrentProject({ projectUuid }) {
  const queryResult = useQuery(
    ['fetchProject', projectUuid],
    async ({ queryKey }) => {
      const [, projectUuid] = queryKey;
      const projects = await getProjectsList(`uuid=${projectUuid}`, false);
      let accesses = {};
      try {
        accesses = (await userAPIs.getRoleAccesses(projectUuid))?.message?.accesses;
      } catch {}
      const project = { ...projects[0], accesses };
      setCurrentProject(project);
      return project;
    },
    { enabled: !!projectUuid, staleTime: 0 }
  );

  return { projectUuid, ...queryResult };
}
