import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ProjectCardMain from '../project-card-main';
import classes from './project-list.module.sass';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import { uniqueId } from 'lodash';
import Loader from '../loader';
import { Box, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';

function ProjectGrid({ projects }) {
  let projectsIndex = [];
  projects
    .sort((a, b) => a.dateStartPlan < b.dateStartPlan)
    .forEach((project) => {
      let [curYear, curMonth] = moment.unix(project.dateStartPlan).format('YYYY:MMMM').split(':');

      let index = projectsIndex.findIndex((el) => {
        console.log(el.year, curYear, el.month, curMonth);
        return el.year === curYear && el.month === curMonth;
      });
      console.log(index);
      if (index === -1) projectsIndex.push({ year: curYear, month: curMonth, projects: [project] });
      else projectsIndex[index].projects.push(project);
    });

  return (
    <React.Fragment key={'ProjectsByYear'}>
      {projectsIndex.map((el, i, arr) => {
        return (
          <Box>
            <Stack flexDirection="row" sx={{ borderBottom: '1px solid #D2D2D2', justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  color: '#D2D2D2',
                  fontSize: '18px',
                  fontWeight: '700',
                  fontFamily: 'Arial !important',
                  lineHeight: '35px',
                }}
              >
                {el.month}
              </Typography>
              {el.year !== arr[i - 1]?.year && (
                <Typography
                  sx={{
                    textTransform: 'capitalize',
                    color: '#D2D2D2',
                    fontSize: '25px',
                    fontWeight: '900',
                    fontFamily: 'Arial !important',
                    lineHeight: '35px',
                  }}
                >
                  {el.year}
                </Typography>
              )}
              <Typography sx={{ visibility: 'hidden' }}>{el.month}</Typography>
            </Stack>
            <Grid container mt={2} rowSpacing={2} gap={'15px'} justifyContent="flex-start">
              {el.projects.map((project) => (
                <Grid key={uniqueId('project_') + i + 1} item>
                  <ProjectCardMain project={project} />
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      })}
    </React.Fragment>
  );
}

export const ProjectList = ({ projects = [] }) => {
  const isLoadingProjects = useSelector((state) => state.app.isLoadingProjects);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  projects = [...projects];

  function EmptyBox() {
    return (
      <Box
        style={{
          textAlign: 'center',
          display: 'block',
          fontSize: 18,
          margin: '4rem auto',
          color: 'rgba(60, 60, 67, 0.6)',
        }}
      >
        {TEXT[lang].NO_PROJECTS}
      </Box>
    );
  }

  return (
    <>
      {isLoadingProjects ? (
        <Loader sx={{ margin: '2rem auto', paddingBottom: '2rem' }} />
      ) : projects.length > 0 ? (
        <ProjectGrid projects={projects} />
      ) : (
        <EmptyBox />
      )}
    </>
  );
};
